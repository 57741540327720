import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventService {

    private loadingSubject = new Subject<any>();

    publish(data: any) {
        this.loadingSubject.next(data);
    }

    getObservable(): Subject<any> {
        return this.loadingSubject;
    }
}
