import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { EventService } from '@services';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet><ngx-spinner type="pacman"></ngx-spinner>'
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private event: EventService,
    private spiner: NgxSpinnerService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    moment.locale('id');

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    // Global Events subscriber
    this.event.getObservable().subscribe((event) => {

      if (event.type == 'toast') {

        if (event.toastType == 'error') {
          this.toastr.error(event.message, event.title, event.options);
        }
        if (event.toastType == 'success') {
          this.toastr.success(event.message, event.title, event.options);
        }

      }

      if (event.type == 'isLoading') {
        if (event.loading) {
          this.spiner.show();
        } else {
          this.spiner.hide();
        }
      }
    });
  }
}
