import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { storageConstant } from '@constants';
import { INavData } from '@coreui/angular';
import { StorageMap } from '@ngx-pwa/local-storage';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems: INavData[] = [];
  public isReady: boolean = false;

  constructor(
    private storage: StorageMap,
    private router: Router,
    private spinner: NgxSpinnerService
  ) {
    this.getMenu();
  }

  getMenu() {
    this.spinner.show('sidebarSpinner');
    this.storage.get(storageConstant.menus).subscribe((menu: INavData[]) => {
      this.navItems = menu;
      this.isReady = true;
      this.spinner.hide('sidebarSpinner');
    });

  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  async logout() {
    this.spinner.show();
    await this.storage.clear().toPromise();
    this.router.navigate(['login'], { replaceUrl: true });
    this.spinner.hide();
  }
}
