import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { EventService } from '@services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    public event: EventService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {

      // console.log(err);

      const error = err.error.message || err.statusText;
      const errorCode = err.error.error_code || 0;
      const options = { positionClass: 'toast-bottom-center', preventDuplicates: true };

      // console.log(`ErrorInterceptor trigered ${err.status}`);
      // console.log(`ErrorInterceptor message ${error}`);

      this.event.publish({ type: 'isLoading', loading: false });

      if (err.status === 401) {
        this.event.publish({
          type: 'toast',
          title: 'Auth failed!',
          toastType: 'error',
          message: error,
          options: options
        });

        if(error[0] != 'Password Anda Salah') {
          this.router.navigate(['login']);
        }
      }

      else if (err.status === 403 || err.status === 500 || err.status === 406 || err.status === 400) {
        this.event.publish({
          type: 'toast',
          title: 'Oops !',
          toastType: 'error',
          message: error,
          options: options
        });
      }

      else if (err.status === 0) {

        this.event.publish({
          type: 'toast',
          title: 'Oops !',
          toastType: 'error',
          message: 'Ada masalah dengan koneksi, silahkan coba beberapa saat lagi',
          options: options
        });
      }

      // else {
      //   // this.event.publish('showErrorAlert', { message: error });
      // }

      return throwError(error);
    }));
  }
}
