import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StorageMap } from '@ngx-pwa/local-storage';

import { storageConstant } from '@constants';
import { AuthService, EventService } from '@services';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  form: FormGroup;
  post: any;
  redirectTo: Array<string> = ['dashboard'];

  constructor(
    private fb: FormBuilder,
    private storage: StorageMap,
    private router: Router,
    private event: EventService,
    private authService: AuthService
  ) {

    this.form = this.fb.group({
      'identifier': [null, Validators.compose([Validators.required])],
      'password': [null, Validators.compose([Validators.required, Validators.minLength(4)])],
      "type": ["bo"],
      "platform": ["web-app"],
    });
  }

  async onSubmit(post) {

    this.event.publish({ type: 'isLoading', loading: true });

    const authInfo = await this.authService.login(post).toPromise();

    await this.storage.set(storageConstant.tokenKey, authInfo.token).toPromise();
    await this.storage.set(storageConstant.userInfo, authInfo.user).toPromise();
    await this.storage.set(storageConstant.menus, authInfo.menus).toPromise();

    this.event.publish({ type: 'isLoading', loading: false });
    this.router.navigate(this.redirectTo, { replaceUrl: true });

  }
}
