import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { StorageMap } from '@ngx-pwa/local-storage';

import { environment } from '@env';
import { storageConstant } from '@constants';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private storage: StorageMap) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return from(this.storage.get(storageConstant.tokenKey))
            .pipe(
                switchMap(token => {
                    if (token) {
                        request = request.clone({
                            setHeaders: {
                                Authorization: `Bearer ${token}`
                            }
                        });
                    }

                    // TO BE REVIEW: CONTENT TYPE AUTO FILL BY HTTPCLIENT,
                   // EXPLICIT USE OF CONTENT TYPE WILL AFFECT UPLOAD FILE VIA FORMDATA

                    request = request.clone({
                        setHeaders: {
                            // "Content-Type": 'application/json',
                            'X-localization': 'id',
                            // "AppName": environment.appName,
                            // "AppBuild": environment.build,
                            // "AppVersion": environment.appVersion
                        }
                    });

                    return next.handle(request);
                })
            );
    }
}
