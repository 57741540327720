import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard, PermissionGuard } from "@guards";

// Import Containers
import { DefaultLayoutComponent } from "./containers";

import { P404Component } from "./pages/error/404.component";
import { P500Component } from "./pages/error/500.component";
import { LoginComponent } from "./pages/login/login.component";
import { RegisterComponent } from "./pages/register/register.component";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "404",
    component: P404Component,
    data: {
      title: "Page 404",
    },
  },
  {
    path: "500",
    component: P500Component,
    data: {
      title: "Page 500",
    },
  },
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login Page",
    },
  },
  {
    path: "register",
    component: RegisterComponent,
    data: {
      title: "Register Page",
    },
  },
  {
    path: "",
    component: DefaultLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      // title: 'Home',
      module: "/dashboard",
    },
    children: [
      {
        path: "schools",
        canActivate: [PermissionGuard],
        data: {
          module: "/schools",
        },
        loadChildren: () =>
          import("./pages/schools/schools.module").then((m) => m.SchoolsModule),
      },
      {
        path: "roles",
        canActivate: [PermissionGuard],
        data: {
          module: "/roles",
        },
        loadChildren: () =>
          import("./pages/roles/roles.module").then((m) => m.RolesModule),
      },
      {
        path: "users",
        canActivate: [PermissionGuard],
        data: {
          module: "/users",
        },
        loadChildren: () =>
          import("./pages/users/users.module").then((m) => m.UsersModule),
      },
      {
        path: "exam-categories",
        canActivate: [PermissionGuard],
        data: {
          module: "/exam-categories",
        },
        loadChildren: () =>
          import("./pages/exam-categories/exam-categories.module").then((m) => m.ExamCategoriesModule),
      },
      {
        path: "academic-years",
        canActivate: [PermissionGuard],
        data: {
          module: "/academic-years",
        },
        loadChildren: () =>
          import("./pages/academic-years/academic-years.module").then(
            (m) => m.AcademicYearsModule
          ),
      },
      {
        path: "manage",
        loadChildren: () =>
          import("./pages/manage/manage.module").then((m) => m.ManageModule),
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./pages/notifications/notifications.module").then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: "quesioners",
        canActivate: [PermissionGuard],
        data: {
          module: "/quesioners",
        },
        loadChildren: () =>
          import("./pages/quesioners/quesioners.module").then(
            (m) => m.QuesionersModule
          ),
      },
      {
        path: "officials",
        canActivate: [PermissionGuard],
        data: {
          module: "/officials",
        },
        loadChildren: () =>
          import("./pages/officials/officials.module").then(
            (m) => m.OfficialsModule
          ),
      },
      {
        path: "base",
        loadChildren: () =>
          import("./views/base/base.module").then((m) => m.BaseModule),
      },
      {
        path: "buttons",
        loadChildren: () =>
          import("./views/buttons/buttons.module").then((m) => m.ButtonsModule),
      },
      {
        path: "charts",
        loadChildren: () =>
          import("./views/chartjs/chartjs.module").then((m) => m.ChartJSModule),
      },
      {
        path: "dashboard",
        loadChildren: () =>
          import("./views/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: "icons",
        loadChildren: () =>
          import("./views/icons/icons.module").then((m) => m.IconsModule),
      },
      {
        path: "notifications",
        loadChildren: () =>
          import("./views/notifications/notifications.module").then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: "theme",
        loadChildren: () =>
          import("./views/theme/theme.module").then((m) => m.ThemeModule),
      },
      {
        path: "widgets",
        loadChildren: () =>
          import("./views/widgets/widgets.module").then((m) => m.WidgetsModule),
      },
    ],
  },
  { path: "**", component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
