import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '@env';
import { IAuth } from '@interfaces';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(
    private http: HttpClient
  ) { }

  login(data) {
    const param = { ...data, type: 'bo' };

    return this.http.put<IAuth>(`${environment.apiEndpoint}auth/login`, param);
  }

  register(data) {
    return this.http.post(`${environment.apiEndpoint}auth/register`, data);
  }

  verify(data) {
    return this.http.patch(`${environment.apiEndpoint}auth/user-verification/${data}`, {});
  }

  changePassword(id, params) {
    return this.http.patch(`${environment.apiEndpoint}auth/change-password/${id}`, params);
  }

  forgotPassword(params) {
    return this.http.patch(`${environment.apiEndpoint}auth/forgot-password`, params);
  }

  addNewUser(data) {
    return this.http.post(`${environment.apiEndpoint}auth/add-user`, data);
  }
}
