import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageMap } from '@ngx-pwa/local-storage';

import { storageConstant } from '@constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, public storage: StorageMap) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return new Promise((resolve) => {
        this.storage.get(storageConstant.tokenKey).subscribe(token => {
          if (token) {
            resolve(true);
          } else {;
            this.router.navigate(['login']);
            resolve(false);
          }
        });
      });
    }

}
