export const schoolTypesConstant: any = [
	{value: "SD", text: "SD"},
	{value: "SMP", text: "SMP"},
	{value: "SMA", text: "SMA"},
    {value: "SMK", text: "SMK"},
    {value: "SLB", text: "SLB"}
]

export const pageLimitsConstant: any = [
	{value: 10, text: "10"},
	{value: 20, text: "20"},
	{value: 50, text: "50"},
	{value: 100, text: "100"}
]

export const civilStatusesConstant: any = [
	{value: "Kawin", text: "Kawin"},
    {value: "Belum Kawin", text: "Belum Kawin"},
    {value: "Duda", text: "Duda"},
    {value: "Janda", text: "Janda"}
]